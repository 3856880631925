import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import InputHome from './pages/input/Home';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import ja from 'date-fns/locale/ja';
import React from 'react';
registerLocale('ja', ja);
setDefaultLocale('ja');

const AdminHome = React.lazy(() => import('./pages/admin/Home'));

const App: React.FC = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="admin/*" element={
          <React.Suspense fallback={<>...</>}>
            <AdminHome />
          </React.Suspense>
        } />
        <Route path="input/*" element={<InputHome />} />
      </Routes>
    </div>
  );
}

export default App;
