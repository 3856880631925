import { Route, Routes } from "react-router";
import InputPeople from "./People";
import InputSpots from "./Spots";

const Home: React.FC = () => {
  return <div className="container-fluid">
    <Routes>
      <Route path="/" element={<InputSpots />} />
      <Route path=":spotId" element={<InputPeople />} />
    </Routes>
  </div>
}

export default Home;
