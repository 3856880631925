import { Link } from "react-router-dom";
import { useGetSpotsQuery } from "../../app/services/api";

const InputSpots: React.FC = () => {
  const { data } = useGetSpotsQuery();

  const spots = (data || []).filter(spot => !spot.personalUse);

  return (<>
    <h1 className="my-4">
      見守り（機会一覧）
    </h1>
    <div className="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 g-3">
      {spots.map(spot => (<div key={spot.id} className="col">
        <Link to={spot.id} className="card shadow-sm">
          { spot.photoUuid && <img
            className="card-img-top"
            alt={spot.name}
            src={`https://ucarecdn.com/${spot.photoUuid}/-/scale_crop/500x500/smart_objects_faces/-/quality/smart/${spot.photoUuid}` }
          /> }
          <div className="card-body">
            <p className="card-text text-center">
              {spot.name}
            </p>
          </div>
        </Link>
      </div>))}
    </div>
  </>)
}

export default InputSpots;
