import React, { useCallback, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useParams } from "react-router";
import { useCreateEventMutation, useGetPeopleQuery, useGetSpotsQuery } from "../../app/services/api";
import DatePicker from 'react-datepicker';
import { startOfDay, isToday } from "date-fns";
import "./People.css";
import "react-datepicker/dist/react-datepicker.css";

interface PersonFormControlsCollection extends HTMLFormControlsCollection {
  'person[]': NodeList
}

const InputPeople: React.FC = () => {
  const { spotId } = useParams();

  const formRef = useRef<HTMLFormElement>(null);
  const [ currentlySending, setCurrentlySending ] = useState(false);
  const [ postSendModal, setPostSendModal ] = useState(false);
  const [ createEvent ] = useCreateEventMutation();

  const { data: spotListData } = useGetSpotsQuery();
  const spot = spotListData && spotListData.find(({id}) => id === spotId);

  const { data: peopleListData } = useGetPeopleQuery();
  const peopleList = peopleListData && peopleListData.filter(person => !person.hidden);

  const [ reportingDate, setReportingDate ] = useState(new Date());

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(async (event) => {
    event.preventDefault();
    if (!spot) {
      // not initialized yet
      return;
    }
    const elements = event.currentTarget.elements as PersonFormControlsCollection;
    const peopleIds: string[] = [];
    for (const x of elements['person[]']) {
      const r = x as HTMLInputElement;
      if (r.checked) peopleIds.push(r.value);
    }
    if (peopleIds.length === 0) {
      return;
    }

    setCurrentlySending(true);
    for (const personId of peopleIds) {
      await createEvent({
        personId,
        spotId: spot.id,
        timestamp: reportingDate.toISOString(),
        eventType: 'MANUAL',
      });
    }

    formRef.current?.reset();
    setCurrentlySending(false);
    setPostSendModal(true);
  }, [createEvent, reportingDate, spot]);

  const handlePostSendModalClose = useCallback(() => {
    setPostSendModal(false);
    window.scrollTo(0, 0);
  }, []);

  if (!spot || !peopleList) return null;

  return (<>
    <h1 className="my-4 d-flex flex-column-reverse flex-md-row">
      <span className="text-center">
        {spot.photoUuid && <img
          className="d-inline-block me-2"
          style={{ height: "2em" }}
          alt={spot.name}
          src={`https://ucarecdn.com/${spot.photoUuid}/-/scale_crop/128x128/smart_objects_faces/-/quality/smart/${spot.photoUuid}` }
        />}
        {spot.name}
      </span>
      <span className="mx-auto mb-3 mb-md-0 me-md-0 ms-md-auto fs-4">
        <DatePicker
          todayButton="今日"
          selected={reportingDate}
          className="text-center"
          maxDate={new Date()}
          onChange={(selectedDate) => {
            if (!selectedDate || Array.isArray(selectedDate)) return;
            if (isToday(selectedDate)) {
              setReportingDate(new Date());
            } else {
              setReportingDate(startOfDay(selectedDate));
            }
          }}
        />
      </span>
    </h1>
    <form onSubmit={handleSubmit} ref={formRef}>
      <div className="people-row row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 g-3 mb-4">
        {peopleList.map(person => (<div key={person.id} className="col">
          <input
            type="checkbox"
            id={`person-${person.id}`}
            name="person[]"
            value={person.id}
            className="d-none"
          />
          <label htmlFor={`person-${person.id}`}>
            <div className="card shadow-sm">
              { person.photoUuid && <img
                className="card-img-top"
                alt={person.name}
                src={`https://ucarecdn.com/${person.photoUuid}/-/scale_crop/500x500/smart_faces_objects/-/quality/smart/${person.photoUuid}` }
              /> }
              <div className="card-body">
                <p className="card-text text-center">
                  {person.name}
                </p>
              </div>
            </div>
          </label>
        </div>))}
      </div>
      <div
        className="d-flex justify-content-center position-sticky bottom-0 start-0 end-0 py-4 bg-white"
        style={{ 'margin': '0 calc(var(--bs-gutter-x,.75rem) * -1)' }}
      >
        <button
          type="reset"
          className="mx-1 btn btn-secondary"
          disabled={currentlySending}
        >
          リセット
        </button>
        <button
          type="submit"
          className="mx-1 btn btn-primary btn-lg"
          disabled={currentlySending}
        >
          送信
        </button>
      </div>
    </form>
    <Modal show={postSendModal} onHide={handlePostSendModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>送信しました</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        送信が完了しました。
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handlePostSendModalClose}>
          確認しました
        </Button>
      </Modal.Footer>
    </Modal>
  </>)
}

export default InputPeople;
