import { Link } from "react-router-dom";

const Home: React.FC = () => {
  return <>
    <h1>男木島スマートアイランド</h1>
    <ul>
      <li><Link to="input">入力画面</Link></li>
    </ul>
  </>
}

export default Home;
