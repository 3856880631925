// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

type DraginoDoor = {
  sensorType: "door"
  BAT_V: number
  MOD: number
  DOOR_OPEN_STATUS: number
  DOOR_OPEN_TIMES: number
  LAST_DOOR_OPEN_DURATION: number
  ALARM: number
}

type DraginoGps = {
  sensorType: "gps"
  Latitude: number
  Longitud: number
  Roll: number
  Pitch: number
  BatV: number
  ALARM_status: boolean
  MD: string
  LON: boolean
  FW: number
  HDOP: number
  Altitude: number
}

export type RawIotEventsResponse = {
  timestamp: string
  lora: {
    rx_data_id: number,
    end_device_id: string,
    gateway_id: string,
    received_time: number,
    sequence_number: number,
    port: number,
    radio_id: number,
    channel: number,
    rssi_dbm: number,
    snr_db: number,
    frequency_hz: number,
    modulation_type: string,
    data_rate: string,
    coding_rate: string,
    adr: boolean,
    payload: string
  }
  payload: DraginoDoor | DraginoGps
}[]

export type Spot = {
  id: string
  name: string
  photoUuid?: string
  /** このフラグが立ってる場合は、IoTデバイスなどのために使われているので、手動選択一覧に出す必要がない */
  personalUse?: boolean
}

export type Person = {
  id: string
  name: string
  photoUuid: string
  hidden?: boolean
}

export type EventObj = {
  id: string
  personId: string
  spotId: string
  eventType: 'MANUAL' | 'DOOR' | 'GPS' | 'OTHER'
  memo?: string
  alert?: boolean
  timestamp: string
}

export type CreateArg<T = any> = Omit<T, 'id'>
export type UpdateArg<T = any> = { id: string } & Partial<Omit<T, 'id'>>

// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://ogijima-api.geoloniamaps.com/dev/' }),
  tagTypes: ['Spot', 'Person', 'Event'],
  endpoints: (builder) => ({
    getDemo: builder.query<any, void>({
      query: () => ``,
    }),
    getRawIotEvents: builder.query<RawIotEventsResponse, void>({
      query: () => `rawIotEvents`,
      transformResponse: (resp: any) => resp.data,
    }),
    getSpots: builder.query<Spot[], void>({
      query: () => `spots`,
      transformResponse: (resp: any) => resp.data,
      providesTags: (result) => {
        return (
          result ?
          [
            ...result.map(({id}) => ({ type: 'Spot', id } as const)),
            { type: 'Spot', id: 'LIST' },
          ] : [
            { type: 'Spot', id: 'LIST' },
          ]
        )
      },
    }),
    createSpot: builder.mutation<Spot, CreateArg<Spot>>({
      query: (spot) => ({
        url: `spots`,
        method: 'POST',
        body: spot,
      }),
      invalidatesTags: [
        { type: 'Spot', id: 'LIST' },
      ],
    }),
    updateSpot: builder.mutation<Spot, UpdateArg<Spot>>({
      query: (spot) => ({
        url: `spots/${spot.id}`,
        method: 'PATCH',
        body: { ...spot, id: undefined },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Spot', id: 'LIST' },
        { type: 'Spot', id },
      ],
    }),
    getSpotEvents: builder.query<EventObj[], {spotId: string}>({
      query: ({spotId}) => `spots/${spotId}/events`,
      transformResponse: (resp: any) => resp.data,
      providesTags: (result, _error, { spotId }) => {
        return (
          result ?
          [
            ...result.map(({id}) => ({ type: 'Event', id } as const)),
            { type: 'Event', id: `Spot#${spotId}` },
          ] : [
            { type: 'Event', id: `Spot#${spotId}` },
          ]
        )
      },
    }),
    getPeople: builder.query<Person[], void>({
      query: () => `people`,
      transformResponse: (resp: any) => resp.data,
      providesTags: (result) => {
        return (
          result ?
          [
            ...result.map(({id}) => ({ type: 'Person', id } as const)),
            { type: 'Person', id: 'LIST' },
          ] : [
            { type: 'Person', id: 'LIST' },
          ]
        )
      }
    }),
    createPerson: builder.mutation<Person, CreateArg<Person>>({
      query: (person) => ({
        url: `people`,
        method: 'POST',
        body: person,
      }),
      invalidatesTags: [
        { type: 'Person', id: 'LIST' },
      ],
    }),
    updatePerson: builder.mutation<Person, UpdateArg<Person>>({
      query: (person) => ({
        url: `people/${person.id}`,
        method: 'PATCH',
        body: { ...person, id: undefined },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Person', id: 'LIST' },
        { type: 'Person', id },
      ],
    }),
    getPersonEvents: builder.query<EventObj[], {personId: string}>({
      query: ({personId}) => `people/${personId}/events`,
      transformResponse: (resp: any) => resp.data,
      providesTags: (result, _error, { personId }) => {
        return (
          result ?
          [
            ...result.map(({id}) => ({ type: 'Event', id } as const)),
            { type: 'Event', id: `Person#${personId}` },
          ] : [
            { type: 'Event', id: `Person#${personId}` },
          ]
        )
      },
    }),

    createEvent: builder.mutation<EventObj, CreateArg<EventObj>>({
      query: (event) => ({
        url: `events`,
        method: 'POST',
        body: event,
      }),
    }),
  }),
});

export const {
  useGetDemoQuery,
  useGetRawIotEventsQuery,

  useGetSpotsQuery,
  useCreateSpotMutation,
  useUpdateSpotMutation,
  useGetSpotEventsQuery,

  useGetPeopleQuery,
  useCreatePersonMutation,
  useUpdatePersonMutation,
  useGetPersonEventsQuery,

  useCreateEventMutation,
} = api
